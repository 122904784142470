<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="12"
      >
        <validation-observer
          ref="ResetPasswordForm"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="onSubmit"
          >
            <b-form-group
              label-for="email"
              label="Email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                immediate
                rules="required|email"
              >
                <b-form-input
                  v-model="userEmail"
                  name="Email"
                  :state="errors.length > 0 ? false:null"
                  placeholder="john@example.com"
                  autofocus
                  autocomplete="off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Nueva Contraseña</label>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Contraseña"
                immediate
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="Nueva Contraseña"
                    autocomplete="new-password"
                    @keyup.enter="onSubmit"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-card-text class="text-center mt-2">
                <span>¿Ya tienes cuenta? </span>
                <b-link>
                  <slot name="login" />
                </b-link>
              </b-card-text>
            </b-form-group>

            <!-- submit button -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
              type="submit"
              @keyup.enter="onSubmit"
              :disabled="isLoading"
            >
              <b-spinner
                v-if="isLoading"
                small
                type="grow"
              />
              <span class="align-middle">Establecer nueva contraseña</span>
            </b-button>

            <b-form-group>
              <b-card-text class="text-center mt-2">
                <span> Al crear una cuenta aceptas nuestros Terminos de Uso y </span>
                <b-link href="https://travelbus.com.co/politicas-de-privacidad/" target="_blank">
                  <span class="text-primary">Políticas</span>
                </b-link>
                de
                <b-link href="https://travelbus.com.co/politicas-de-privacidad/" target="_blank">
                  <span class="text-primary">Privacidad.</span>
                </b-link>
              </b-card-text>
            </b-form-group>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BCol, BFormGroup, BFormInput,
  BLink, BRow, BInputGroup, BInputGroupAppend, BCardText, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'
import { mapGetters } from 'vuex'

export default {
  name: 'Register',
  components: {
    BButton,
    BForm,
    BCol,
    BFormGroup,
    BFormInput,
    BLink,
    BRow,
    BInputGroup,
    BInputGroupAppend,
    BCardText,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userEmail: '',
      password: '',
      isLoading: false,
      passwordFieldType: 'password',
      // validation rules
      required,
      email,
    }
  },
  computed: {
    ...mapGetters({ 
      tokenPassword: 'userApp/getToken'
    }),
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    onSubmit() {
      this.$refs.ResetPasswordForm.validate().then(async success => {
        if (success) {
          this.isLoading = true
          await this.$http.get('/csrf-cookie')
          this.$http.post('/auth/reset', {
            email: this.userEmail,
            token: this.tokenPassword.token,
            password: this.password,
          }).then(() => {
            this.isLoading = false
            this.$router.replace({ query: null })
            store.dispatch('userApp/setTokenPassword', null)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Mensaje',
                icon: 'CheckIcon',
                variant: 'success',
                text: 'Tu nueva contraseña ha sido establecida! Por favor inicia sesión nuevamente',
              },
            },
            {
              position: 'top-center',
            })
          }).catch(error => {
            this.isLoading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Mensaje',
                icon: 'XIcon',
                variant: 'danger',
                text: error.response.data.message,
              },
            },
            {
              position: 'top-center',
            })
          })
        }
      })
    },
  },
}

</script>
